
import React from "react"
import { useGetMechaReleaseInfoQuery } from "./graphql/generated"
import Button from '@mui/material/Button'
import RefreshIcon from "@mui/icons-material/Refresh"
import { useWeaverFlags } from "./api/thirdparty/launchDarkly/useWeaverFlags"
import { useCoreApiSource } from "./common/hooks/useCoreApiSource"
import { IconButton, Snackbar } from "@mui/material"

const ReleaseVersionChecker: React.FC = () => {
  const weaverFlags = useWeaverFlags()

  const gqlDatasource = useCoreApiSource()
  const getMechaReleaseInfoQuery = useGetMechaReleaseInfoQuery(gqlDatasource, {}, {
    staleTime: 10 * 60 * 1000, // 10 minutes
    refetchOnWindowFocus: false,
  })

  const isBrowserOutOfDate = weaverFlags['MW-2163-improve-invalidations']
    ? process.env.REACT_APP_RELEASE_VERSION != null && // I have a browser version
      getMechaReleaseInfoQuery.data?.getReleaseInfo.opsDashboardFrontendVersion != null && // I have a deployed version
      process.env.REACT_APP_RELEASE_VERSION !== getMechaReleaseInfoQuery.data?.getReleaseInfo.opsDashboardFrontendVersion // browser version !== deployed version
    : false // never show the toast if the flag is off

  const doRefresh = () => {
    const url = new URL(window.location.href)
    url.searchParams.set('reloadTime', Date.now().toString())
    window.location.href = url.toString()
  }

  return (
    <Snackbar
      open={isBrowserOutOfDate}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      title='New Release!'
      message='Reload this page to get the latest Weaver updates.'
      action={
        <React.Fragment>
          <Button color="secondary" size="small" onClick={doRefresh}>
            Reload
          </Button>
          <IconButton size="small" aria-label="reload" color="inherit" onClick={doRefresh}>
            <RefreshIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      }
    />
  )
}

export default ReleaseVersionChecker
